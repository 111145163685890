/* SCSS format */

.creditpack-type-box {
  border-radius: 10px;
  border-top: 2px solid #f6f4f5;
  border-left: 2px solid #f6f4f5;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 28px 40px;
  margin-bottom: 30px;

  h5 {
    font-size: 30px;
    font-weight: 600;
    max-width: max-content;
    padding-bottom: 14px;
  }

  p {
    font-size: 24px;
    font-weight: 500;
  }
}

.creditpack-detail {
  max-width: 660px;
  margin: 0 auto;

  h5 {
    max-width: 200px;
    width: 100%;
    font-size: 22px;
  }

  p {
    max-width: 400px;
    width: 100%;
    font-size: 22px;
    font-weight: 500;
  }
}

.max-w-max {
  white-space: nowrap;
}
/**
 * The trialpack-heading class contains styles for the heading of the trial pack section.
 */
 .trialpack-heading {
  h2 {
    color: #2e2d32;
    font-family: Quicksand;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    padding-bottom: 2px;
    padding-bottom: 8px;
  }

  p {
    font-weight: 600;
    font-size: 20px;
  }

  margin-bottom: 36px;
}

/**
   * The expiry-option class contains styles for the expiry option element.
   */
.expiry-option {
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 0;
}

/**
   * The w-600 class sets the maximum width of an element to 600px.
   */
.w-600 {
  max-width: 600px;
  width: 100%;
}

/**
   * The trialpack-section class contains styles for the trial pack section.
   */
.trialpack-section {
  max-width: 700px;
  margin: 0 auto;
  padding: 25px 0;
}

/**
   * The w-25 class sets the width of an element to 25%.
   */
.w-25 {
  width: 25%;
}

/**
   * The w-50 class sets the width of an element to 50%.
   */
.w-50 {
  width: 50%;
}

/**
   * The original-discount-price class contains styles for the original and discount price elements.
   */
.original-discount-price {
  display: flex;
  gap: 20px;

  input {
    width: 150px !important;
  }
}

/**
   * The adv-chart-unlock class contains styles for the advanced chart unlock element.
   */
.adv-chart-unlock {
  position: absolute;
  right: -115px;
  top: 25px;
  width: 250px;
  height: 390px;
}
