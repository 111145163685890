/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

/* Variables */
$primary-color: #baede7;
$lightblack-color: #2e2d32;
$belticsea-color: #2e2d32;
$wildsand-color: #f6f4f5;
$regentgrey-color: #919eab;
$anthengrey-color: #dfe3e8;
$blue-color: #4200ff;

:root {
  --primary-color: #baede7;
  --lightblack-color: #2e2d32;
  --belticsea-color: #2e2d32;
  --wildsand-color: #f6f4f5;
  --regentgrey-color: #919eab;
  --anthengrey-color: #dfe3e8;
  --blue-color: #4200ff;
}

/* reset styles */
h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a,
input {
  margin-bottom: 0;
}

/* body styles */
body {
  font-family: "quicksand", sans-serif;
  background-color: var(--primary-color);
}

/* input file styles */
input[type="file"]::-webkit-file-upload-button,
input[type="file"]::-moz-file-upload-button,
input[type="file"]::-ms-file-upload-button,
input[type="file"]::-o-file-upload-button {
  cursor: pointer;
}

/* cursor styles */
input[type="file"],
a[href],
input[type="submit"],
input[type="button"],
input[type="image"],
label[for],
select,
button,
.pointer {
  cursor: pointer;
}

/* remove inner focus outline */
::-moz-focus-inner,
::-webkit-focus-inner {
  border: 0px solid transparent;
}

/* text selection styles */
*::-moz-selection {
  color: #fff;
  background: #000;
}
*::-webkit-selection {
  color: #fff;
  background: #000;
}

/* input placeholder styles */
*::-webkit-input-placeholder {
  color: #333333;
  opacity: 1;
}
*:-moz-placeholder {
  color: #333333;
  opacity: 1;
}
*::-moz-placeholder {
  color: #333333;
  opacity: 1;
}
*:-ms-input-placeholder {
  color: #333333;
  opacity: 1;
}

/* link styles */
a,
div a:hover,
div a:active,
div a:focus,
button {
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

/* link styles for span, div and button */
a,
span,
div a:hover,
div a:active,
button {
  text-decoration: none;
}

/* box-sizing styles */
*::after,
*::before,
* {
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

/* remove list styles */
.no-list li,
.no-list ul,
.no-list ol,
footer li,
footer ul,
footer ol,
header li,
header ul,
header ol {
  list-style: inside none none;
}
section li {
  list-style: none;
}

/* remove margin and padding for lists */
.no-list ul,
.no-list ol,
footer ul,
footer ol,
header ul,
header ol {
  margin: 0;
  padding: 0;
}

/* link styles */
a {
  outline: none;
  color: #555;
}

/* link hover styles */
a:hover {
  color: #000;
}

/* clearfix styles */
body .clearfix,
body .clear {
  clear: both;
  line-height: 100%;
}
body .clearfix {
  height: auto;
}

/* remove outline for all elements */
* {
  outline: none !important;
}

/* table styles */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* scrollbar styles */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: #4d5068;
}

/* scrollbar track styles */
::-webkit-scrollbar-track {
  border-radius: 4rem;
}

/* scrollbar thumb styles */
::-webkit-scrollbar-thumb {
  background: #36e899;
  border-radius: 4rem;
}

/* form control styles */
.form-control {
  background-color: white;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  border-top: 2px solid var(--wildsand-color);
  border-left: 2px solid var(--wildsand-color);
  border-radius: 10px;
  outline: none;
  border: none;
  height: 65px;
  max-width: 580px;
  width: 100%;
}

/* primary button styles */
.primary-btn {
  min-width: 270px;
  min-height: 65px;
  border-radius: 10px;
  @apply bg-black/30;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: max-content;
  font-size: 24px;
  font-weight: 600;
  color: white;
  transition: all 0.5s;
}

/* primary button hover styles */
.primary-btn:hover {
  border: 1px solid var(--lightblack-color);
  color: var(--lightblack-color);
  background-color: white;
}

/* section height styles */
.section-height {
  min-height: calc(100vh - 56px);
  height: 100%;
  width: 100%;
  background-color: var(--primary-color);
}

/* background white styles */
.bg-white {
  background-color: white;
}

/* custom tabbing styles */
.custom-tabbing {
  background-color: var(--primary-color);
}

/* secondary button styles */
.secondary-btn {
  min-height: 50px;
  min-width: 120px;
  border-radius: 10px;
  border: 0.25px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: var(--lightblack-color);
  transition: all 0.5s;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  font-weight: 500;
}

/* secondary button hover styles */
.secondary-btn:hover {
  background-color: black;
  color: white;
}

/* section spacing styles */
.section-spacing {
  padding: 50px 0;
}

/* rounded border styles */
.rounded-b-l {
  border-bottom-left-radius: 10px;
}

.rounded-b-r {
  border-bottom-right-radius: 10px;
}

.rounded-t-r {
  border-top-right-radius: 10px;
}

/* background primary color styles */
.bg-primary {
  background-color: var(--primary-color);
}

/* custom textarea styles */
.custom-textarea {
  border: none;
  outline: none;
  border-radius: 10px;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  resize: none;
  border-top: 2px solid var(--wildsand-color);
  border-left: 2px solid var(--wildsand-color);
  padding: 12px 16px;
}

/* section heading styles */
.section-heading {
  padding: 40px 0;
  font-size: 32px;
  font-weight: 700;
  color: var(--belticsea-color);
}

/* input search styles */
.input-search {
  width: 100%;
  min-height: 80px;
  border-radius: 10px;
  border-top: 2px solid var(--wildsand-color);
  border-left: 2px solid var(--wildsand-color);
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 16px 44px;
  border: none;
  outline: none;
  background-color: white;
}

/* input search placeholder styles */
.input-search::placeholder {
  font-size: 24px;
  font-weight: 300;
  color: #000;
}

/* search box padding styles */
.search-box-padding {
  padding-bottom: 40px;
}

/* search box span styles */
.search-box span {
  position: absolute;
  right: 45px;
  top: 50%;
  transform: translateY(-50%);
}

/* section box styles */
.section-box {
  border-radius: 10px;
  border-top: 2px solid var(--wildsand-color);
  border-left: 2px solid var(--wildsand-color);
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
}

/* tabbing dol styles */
.tabbing-dol {
  max-width: 70px;
}

/* information box title styles */
.information-box-title {
  font-size: 24px;
  font-weight: 500;
}

/* information icon styles */
.information-icon {
  position: relative;
  top: -40px;
}

/* information row styles */
.information-row {
  margin-bottom: 22px;
}

/* information text styles */
.information-text {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: var(--lightblack-color);
  max-width: 606px;
  margin: 0 auto;
}

/* pagination styles */
.pagination {
  gap: 8px;
}

.page-item:first-child .page-link {
  background-color: var(--regentgrey-color);
  color: white;
  font-weight: 700;
  font-size: 18px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 18px;
  font-weight: 700;
}

.page-link {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border: 1px solid var(--anthengrey-color);
  color: black;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
}

.border-blue {
  border: 1px solid var(--blue-color);
  border-radius: 4px;
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}

/* name main styles */
.name-main {
  gap: 30px;
  margin-bottom: 37px;
}

.name-main h4 {
  font-weight: 500;
  font-size: 22px;
}

/* review hextag styles */
.review-hextag p {
  font-size: 18px;
  font-weight: 500;
}

/* review text styles */
.review-text {
  color: var(--lightblack-color);
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  max-width: 700px;
  margin: 0 auto;
}

/* review box styles */
.review-box {
  border-radius: 10px;
  border-top: 2px solid var(--wildsand-color);
  border-left: 2px solid var(--wildsand-color);
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  min-height: 220px;
  padding: 18px 22px 30px 70px;
}

/* review icon styles */
.review-icon {
  position: relative;
  top: -20px;
}

/* first review styles */
.first-review {
  margin-bottom: 40px;
}

/* review box padding styles */
.review-box-padding {
  padding: 40px 65px 70px 65px;
}

/* dropdawn button styles */
.dropdawn-btn {
  min-width: 594px;
  width: 100%;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  color: black;
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 16px 20px;
}

/* add review styles */
.add-review {
  margin-bottom: 55px;
}

/* accordian box styles */
.accordian-box {
  border-radius: 10px;
  border-top: 2px solid $wildsand-color;
  border-left: 2px solid $wildsand-color;
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  min-height: 80px;
}

/* accordion button styles */
.accordion-button:not(.collapsed) {
  color: $lightblack-color;
  background-color: white;
  box-shadow: none;
}

/* accordion body styles */
.accordion-body {
  padding: 24px 0 0 0;
}

/* accordion item styles */
.accordion-item {
  color: $lightblack-color;
  background-color: white;
  border: none;
}

/* accordion button focus styles */
.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

/* prompt categories section styles */
.Promptcategories-section {
  & .select-btn {
    min-width: 400px;
    width: 400px;
  }
}

/* btn check styles */
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background: #fff;
  color: #000;
}

/* css indicator separator styles */
.css-1u9des2-indicatorSeparator {
  display: none;
}

/* css container styles */
.css-b62m3t-container {
  max-width: 576px;
  margin: 0 auto;
}

/* css control styles */
.css-13cymwt-control {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 0.25px solid #000;
  background: #fff;
}

/* css value container styles */
.css-1fdsijx-ValueContainer {
  min-height: 56px;
}

/* react select input placeholder styles */
#react-select-2-input::placeholder {
  font-family: "quicksand";
  color: #000;
}
.form-switch {
  align-self: center;
}
/* header flex styles */
.header-flex {
  display: flex;
  gap: 50px;
  padding: 0 50px;
  height: 95px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

/* nav logo styles */
.nav-logo {
  padding: 15px 0;
}

/* header link styles */
.header-link {
  cursor: pointer;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  color: #000;
  font-family: Quicksand;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

/* body background styles */
body {
  background: #baede7;
}

/* active header link styles */
.header-link.active {
  background: #baede7;
}

/* d margin styles */
.d-margin {
  margin-top: 95px;
}

/* homepage content styles */
.Homepagecontent {
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
  padding: 58px;
}

/* homepage content h1 styles */
.Homepagecontent h1 {
  color: #2e2d32;
  font-family: Quicksand;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom: 50px;
}

/* homepage content ul styles */
.Homepagecontent ul {
  padding: 0;
  list-style: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

/* homepage content ul li styles */
.Homepagecontent ul li {
  width: calc(50% - 30px);
}

/* homepage content ul li a styles */
.Homepagecontent ul li a {
  border-radius: 10px;
  border-top: 2px solid #f6f4f5;
  border-left: 2px solid #f6f4f5;
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding: 20px;
  text-decoration: none;
  transition: all 0.5s;
}

/* homepage content ul li a p styles */
.Homepagecontent ul li a p {
  color: #000;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 auto 0 0;
}

/* homepage content ul li a i styles */
.Homepagecontent ul li a i {
  color: #000;
  font-size: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* homepage content ul li a hover styles */
.Homepagecontent ul li a:hover {
  box-shadow: none;
  background: #fbfbfb;
}

/* custom tab styles */
.custom-tab {
  justify-content: space-between;
}

/* custom tab nav link styles */
.custom-tab .nav-link {
  color: #2e2d32;
  font-family: Quicksand;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 500px;
  width: 100%;
  text-align: center;
  padding: 20px 20px 15px 20px;
  border-radius: 10px 10px 0px 0px;
}

/* custom tab nav item styles */
.custom-tab .nav-item {
  max-width: 380px;
  width: 100%;
}

/* active nav link styles */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #fff;
  color: #000;
  border-radius: 10px 10px 0px 0px;
  background: #fff;
}

/* tab box styles */
.tab-box {
  background: #fff;
  padding: 30px;
}

/* custom form styles */
.custom-form {
  display: flex;
}

/* custom form label styles */
.custom-form label {
  max-width: 230px;
  display: block;
  width: 100%;
  margin: 0;
  padding: 15px 0;
  color: #000;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* custom form control styles */
.custom-form .form-control {
  border-radius: 10px;
  border: navajowhite;
  border-top: 2px solid #f6f4f5;
  border-left: 2px solid #f6f4f5;
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  color: #2e2d32;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding: 15px;
}
/* custom form input style */
.custom-form input {
  border-radius: 10px;
  // border: navajowhite;
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  color: #2e2d32;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding: 15px;
}
.custom-form textarea {
  border-radius: 10px;
  // border: navajowhite;
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  color: #2e2d32;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding: 15px;
}

/* custom form select styles */
.custom-form .form-select {
  border-radius: 10px;
  border: none;
  border-top: 2px solid #f6f4f5;
  border-left: 2px solid #f6f4f5;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  color: #2e2d32;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding: 15px;
}

/* max 50 styles */
.max-50 {
  max-width: 580px;
  margin: 0 auto;
  padding: 50px 0;
}

/* custom form image styles */
.custom-form-Image i {
  margin-right: -120px;
  padding-left: 20px;
  width: 170px;
  height: 100px;
  display: block;
}

/* custom form image img styles */
.custom-form-Image i img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* btn-black styles */
.btn-black {
  border-radius: 10px;
  background: #2e2d32;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 260px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  transition: all 0.5s;

  &:hover {
    background: #000;
    color: #fff;
  }
}

/* view-content styles */
.view-content {
  padding: 30px 0;
  max-width: 828px;

  & h3 {
    text-align: center;
    color: #2e2d32;
    font-family: Quicksand;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 60px;
  }
}

/* custom-search styles */
.custom-search {
  position: relative;

  & input {
    border-radius: 10px;
    border: none;
    border-top: 2px solid #f6f4f5;
    border-left: 2px solid #f6f4f5;
    background: #fff;
    box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
    color: #000;
    font-family: Quicksand;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 65px;
    width: 100%;
    padding: 15px;
    outline: none;
  }

  & a {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
  }
}

/* custom-card styles */
.custom-card {
  margin-top: 50px;
  border-radius: 10px;
  border-top: 2px solid #f6f4f5;
  border-left: 2px solid #f6f4f5;
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 30px;
  margin-bottom: 10px;

  & p {
    color: #2e2d32;
    text-align: center;
    font-family: Quicksand;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 123.623%; /* 39.559px */
    letter-spacing: 1.6px;
    max-width: 80%;
    margin: 0 auto;
  }
}

/* cardMediaAction styles */
.cardMediaAction {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -20px;
  gap: 10px;
  position: relative;
  z-index: 5;
  margin-right: -10px;

  & a {
    font-size: 24px;
    color: #000;
  }
}

/* cardMedia styles */
.cardMedia {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: -30px;

  & span {
    display: block;
    height: 120px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & h6 {
    color: #000;
    font-family: Quicksand;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 20px;
  }

  & p {
    font-family: Quicksand;
  }
}

/* Homepagecontent pagination styles */
.Homepagecontent {
  & .pagination {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    margin-top: 40px;

    & li.page-item {
      width: auto;

      & a,
      & span {
        display: flex;
        width: 32px;
        height: 32px;
        padding: 5px 4px;
        justify-content: center;
        align-items: center;
        color: var(--dark-grey-400, #212b36);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        border-radius: 8px;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        box-shadow: none;
      }
    }

    & .active > .page-link,
    & .page-link.active {
    }

    & .disabled > .page-link,
    & .page-link.disabled {
      color: #fff;
    }
    .pagination-btn-active {
      border: 1px solid #4200ff;
      border-color: #4200ff;
      color: #4200ff;
      background-color: transparent;
    }
    .pagination-btn {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 5px 4px;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      border-radius: 8px;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      box-shadow: none;
    }
  }
}

/* delete-modal styles */
.delete-modal {
  & p {
    margin: 0;
    color: #2e2d32;
    font-family: Quicksand;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 123.623%; /* 29.67px */
    letter-spacing: 1.2px;
    text-align: center;
  }

  & .modal-footer {
    border-top: 0;
    justify-content: center;
  }
}

/* btn-black-border styles */
.btn-black-border.btn {
  border-radius: 10px;
  border: 0.25px solid #2e2d32;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #2e2d32;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 260px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: 10px;
  transition: all 0.5s;
}

/* delete-modal styles */
.delete-modal {
  & .modal-footer {
    & button {
      max-width: 126px;
      height: 50px;
    }
  }

  & .modal-content {
    max-width: 370px;
    padding: 20px;
  }

  & .modal-dialog {
    justify-content: center;
  }
}

/* custom-form styles */
.custom-form {
  & .w-100 span {
    color: #000;
    font-family: Quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 10px;
    display: block;
  }
}

/* AicardMedia styles */
.AicardMedia {
  & h6 {
    i {
      display: block;
      font-style: normal;
    }

    padding: 10px 0 0 0;
  }
}

/* questioncategorymain styles */
.questioncategorymain {
  & h3 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 50px;
  }

  & .btn-black-border.btn {
    max-width: 110px;
    margin: 0 !important;
    align-self: center;
    // margin-left: 20px !important;
    height: 45px;
    border-color: #0002;
    box-shadow: none;
  }
}

/* textarea styles */
textarea {
  resize: none;
}

/* questionaddwrap styles */
.questionaddwrap {
  position: relative;
}

/* questionbox styles */
.questionbox {
  margin-bottom: 20px;
  display: flex;
  border-radius: 10px;
  border-top: 2px solid #f6f4f5;
  border-left: 2px solid #f6f4f5;
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;

  & p {
    margin: 0 auto 0 0;
    color: #000;
    font-family: Quicksand;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  & span {
    display: flex;
    align-items: center;
    gap: 10px;

    & a {
      font-size: 18px;
      color: #000;
    }
  }
}

/* CategoryQuestion styles */
.CategoryQuestion {
  & h5 {
    text-align: center;
    margin: 40px 0 20px;
    color: #000;
    font-family: Quicksand;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

/* LinkQuestion styles */
.LinkQuestion {
  align-items: center;

  & .btn-black-border.btn {
    max-width: 110px;
    margin: 0 !important;
    align-self: center;
    margin-left: 20px !important;
    height: 45px;
    border-color: #0002;
    box-shadow: none;
  }

  & .form-select {
    max-width: 390px;
    margin-left: 20px;
  }

  & label.form-label {
    max-width: unset;
    width: auto;
  }
}

/* link-question-list styles */
.link-question-list {
  gap: 20px !important;
  margin-bottom: 20px !important;

  & li {
    width: max-content !important;
    border-radius: 10px;
    border-top: 2px solid #f6f4f5;
    border-left: 2px solid #f6f4f5;
    background: #fff;
    box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    padding: 10px;
    text-decoration: none;
    transition: all 0.5s;
    font-size: 16px;
    font-weight: 500;
    justify-content: space-between;
    gap: 15px;

    & a {
      all: unset !important;
      cursor: pointer !important;
    }
  }
}

/* modal-card styles */
.modal-card {
  padding: 20px;
  margin-top: 20px;
}

/* modal-card cardMediaAction styles */
.modal-card .cardMediaAction {
  margin-top: -15px;
}

/* modal-info styles */
.modal-info {
  margin-top: -15px;
}

/* modal-info h6 styles */
.modal-info h6 {
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  & i {
    font-style: normal;
    max-width: 180px;
    display: block;
    width: 100%;
  }
}

/* Boardroom-input label styles */
.Boardroom-input label {
  max-width: 270px;
}

/* custom-form Boardroom-input styles */
.custom-form.mb-4.Boardroom-input {
  margin-bottom: 10px !important;
}

/* addCharaterinfo paragraph styles */
p.addCharaterinfo {
  text-align: end;
  color: #000;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 40px;
}

/* Boardroom-select button styles */
.Boardroom-select .btn {
  border-radius: 10px;
  border: 0.25px solid #000;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  max-width: 90px;
  margin-left: 20px;
  height: 40px;
  align-self: center;
}

/* Boardroom-box styles */
.Boardroom-box {
  border-radius: 10px;
  border: 0.25px solid #0004;
  padding: 20px;
  margin-bottom: 30px;
}

/* Boardroom-Media styles */
.Boardroom-Media {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Boardroom-button styles */
.Boardroom-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -10px;
  gap: 7px;
  margin-right: -10px;
  position: relative;

  & a {
    font-size: 21px;
    color: #000;
  }
}

/* Boardroom-Media span styles */
.Boardroom-Media span {
  height: 120px;
  display: block;
}

/* Boardroom-Media h6 styles */
.Boardroom-Media h6 {
  color: #000;
  font-family: Quicksand;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}

/* Boardroom-Media ol styles */
.Boardroom-Media ol {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  gap: 13px;
}

/* Boardroom-Media ol li styles */
.Boardroom-Media ol li {
  font-size: 16px;
  font-weight: 600;
}

/* Boardroom-box p styles */
.Boardroom-box p {
  color: #2e2d32;
  text-align: center;
  font-family: Quicksand;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.623%; /* 29.67px */
  letter-spacing: 1.2px;
  margin: 0;
}

/* accordion-button styles */
.accordion-button {
  display: flex;
  align-items: center;
  gap: 7px;
  color: #000;
  border-radius: 10px !important;
  border-top: 2px solid #f6f4f5;
  border-left: 2px solid #f6f4f5;
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);

  & i a {
    color: #000;
  }

  &:not(.collapsed) {
    background: transparent;
    border-radius: 10px;
    border-top: 2px solid #f6f4f5;
    border-left: 2px solid #f6f4f5;
    background: #fff;
    box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

/* accordion-item styles */
.accordion-item {
  border: navajowhite;
  margin: 20px 0;
}

/* tag-form button styles */
.tag-form .btn {
  max-width: 117px;
  height: 50px;
  align-self: center;
  margin-left: 20px;
  border-color: #0004;
}

/* tags-main h5 styles */
.tags-main h5 {
  font-size: 26px;
  text-align: center;
  margin: 30px 0;
  font-weight: 600;
}

/* tags-main ol styles */
.tags-main ol {
  padding: 0;
  list-style: none;
  margin: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

/* tags-main ol li styles */
.tags-main ol li {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border-top: 2px solid #f6f4f5;
  border-left: 2px solid #f6f4f5;
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  gap: 20px;
  width: calc(33.33% - 10px);

  & p {
    margin: 0;
  }

  & a {
    font-size: 18px;
    margin-left: 6px;
    color: #000;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* sortBY styles */
.sortBY {
  display: flex;
  align-items: center;
  margin: 16px 0 20px;
  gap: 20px;

  & p {
    font-size: 18px;
    margin: 0;
    font-weight: 700;
  }

  & label {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
}

/* form-check-input:checked styles */
.form-check-input:checked {
  background-color: #000;
  border-color: #000;
  box-shadow: none !important;
}

/* perkBox styles */
.perkBox {
  border-radius: 10px;
  border-top: 2px solid #f6f4f5;
  border-left: 2px solid #f6f4f5;
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin-bottom: 20px;

  & span {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & a {
      font-size: 20px;
      color: #000;
      margin-left: 7px;
    }
  }

  & h5 {
    color: #000;
    font-family: Quicksand;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: -30px 0 20px 0;
    text-align: left;
  }

  & h6 {
    display: flex;
    gap: 22px;
  }

  & h6 strong {
    color: #000;
    font-family: Quicksand;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 110px;
    width: 100%;
    display: block;
  }

  & h6 i {
    color: #2e2d32;
    font-family: Quicksand;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

/* perks-selects styles */
.perks-selects {
  gap: 20px;
  justify-content: space-between;

  & .custom-form {
    width: 50%;
  }
}

/* perkCard styles */
.perkCard {
  border-radius: 10px;
  border-top: 2px solid #f6f4f5;
  border-left: 2px solid #f6f4f5;
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & h6 {
    font-size: 18px;
    margin: 0;
    max-width: 170px;
    width: 100%;
    font-weight: 600;
  }

  & p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
  }

  & span {
    margin-left: auto;
  }

  & i {
    font-style: normal;
  }

  & span {
    display: flex;
    align-items: center;
    gap: 10px;

    & a {
      font-size: 19px;
      color: #000;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

/* d-flex.align-items-center.gap-2.pe-3 a styles */
.d-flex.align-items-center.gap-2.pe-3 a {
  font-size: 22px;
  color: #000;
}

/* prompt-form styles */
.prompt-form {
  width: 300px;
}

/* prompt-wrap styles */
.prompt-wrap {
  width: 700px;
}

/* system-prompt-wrap styles */
.system-prompt-wrap {
  max-width: 825px;
}

/* custom-w-version .form-control styles */
.custom-w-version .form-control {
  max-width: 120px;
}

/* custom-w-modal .form-control styles */
.custom-w-modal .form-control {
  max-width: 180px;
}

/* promptshort-title styles */
.promptshort-title {
  max-width: 170px;
  width: 100%;
}

/* all-prompts-detail h5 styles */
.all-prompts-detail h5 {
  max-width: 180px;
  width: 100%;
}

/* all-prompts-detail p styles */
.all-prompts-detail p {
  max-width: 540px;
  width: 100%;
}

/* feedback-select-wrap styles */
.feedback-select-wrap {
  max-width: 984px;
  width: 100%;
  padding-top: 58px;
}

/* feedback-form styles */
.feedback-form {
  width: 400px;
}
