/* SCSS format */

.feedback-blog {
  border-radius: 10px;
  border-top: 2px solid #f6f4f5;
  border-left: 2px solid #f6f4f5;
  background: #fff;
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 25px;
  margin-bottom: 25px;

  h5 {
    font-size: 24px;
  }
}

.feedback-time {
  font-size: 16px;
  font-weight: 600;
}

.feedback-detail {
  font-size: 20px;
  width: 95%;
  font-weight: 600;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.feedback-arrow {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.feedback-padding {
  padding: 50px 0;
}
